import htmlEditButton from "quill-html-edit-button";

const Editor = (function(){

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['link', 'image', 'video'],        // toggled buttons
    [{ 'header': 2 }, { 'header': 3 }],               // custom button values
    [{ 'header': [2, 3, false] }],

    ['blockquote', 'code-block'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent

    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown

    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'align': [] }],
    [{ 'direction': 'rtl' }],                         // text direction

    ['clean']                                         // remove formatting button
  ];

  function init() {
    if (typeof Quill === 'undefined')   {
      return;
    }

    Quill.register({
      "modules/htmlEditButton": htmlEditButton
    })

    document.querySelectorAll('[data-rich-text-editor]').forEach(function(element) {
      const div = document.createElement('div')
      div.dataset.elementRef = element.name
      div.innerHTML = element.value
      element.after(div)

      element.style.display = 'none'
      var quill = new Quill(div, {
        modules: {
          toolbar: toolbarOptions,
          htmlEditButton: {}
        },
        theme: 'snow'
      });

      quill.on('text-change', function(delta, oldDelta, source) {
        element.value = div.querySelector('.ql-editor').innerHTML
      });
    });
  }

  return {
    init: init
  }
})();

export default Editor;
