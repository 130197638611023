const CircleProgress = (function(){

  function init(){

  }

  return {
    init: init
  }
})();

document.addEventListener("DOMContentLoaded", function() {
  CircleProgress.init();
});
