const LongRefreshLink = (function(){

  function init() {
    document.removeEventListener('ajax::refresh', init)
    document.addEventListener('ajax::refresh', init)

    if (document.body.style.pointerEvents == 'none') {
      document.body.style.pointerEvents = ''
    }

    const longLoadElements = Array.from(document.querySelectorAll('[data-long-request]'))
    longLoadElements.forEach(element => {
      attachClickHandlers(element)
      element.classList.remove('action-link-active')
    })
  }

  function attachClickHandlers(element) {
    element.removeEventListener('click', onClick)
    element.addEventListener('click', onClick)
  }

  function onClick(event) {
    document.body.style.pointerEvents = 'none'
    event.currentTarget.classList.add('action-link-active')
  }

  return {
    init: init
  }
})();

export default LongRefreshLink;
