document.addEventListener("DOMContentLoaded", function() {
  const elementKindSelectors = document.querySelectorAll('[data-input-toggler]')
  elementKindSelectors.forEach(function(elementSelector) {
    const selectElement = elementSelector.querySelector('select');
    if (selectElement) {
      selectElement.addEventListener('change', function (event) {
        updateElements(event.currentTarget.value);
      });

      updateElements(selectElement.value);
    }
  });

  function updateElements(selectedValue){
    var inputs = document.querySelectorAll('[data-input-types]');
    inputs.forEach(function(element){
      var optionArray = element.dataset.inputTypes;
      if (optionArray){
        var parsedArray = JSON.parse(optionArray);
        if (parsedArray.includes(selectedValue)){
          element.classList.remove("hidden");
        } else {
          element.classList.add("hidden");
        }
      }
    })
  }
});
