// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import '../polyfills/index'

import '../stylesheets/application.scss'

require("@rails/ujs").start()
require("@rails/activestorage").start()
// require("channels")

import '../elements/index'
import '../models/index'
import '../maps/index'

import '../sse/server_notifications'

import '../pwa/network_manager'
import '../pwa/device_manager'
import '../pwa/serviceworker_registration'
import Editor from "../elements/editor";

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

window.addEventListener("load", function() {
  Editor.init();
});

(function(){
  document.addEventListener('mousemove', e => {
    // console.log( document.elementFromPoint(e.clientX, e.clientY) )
  }, {passive: true})
})();
