const Theme = (function(){

  const init = () => {
    const switchElement = document.getElementById('theme_switch')
    if (switchElement) {
      switchElement.removeEventListener('change', onThemeChanged)
      switchElement.addEventListener('change', onThemeChanged)
    }
  }

  const onThemeChanged = (event) => {
    const selectElement = event.currentTarget;
    const option = selectElement.options[selectElement.selectedIndex];
    document.cookie = "theme=" + encodeURIComponent(option.value) + "; path=/";
    window.location.reload(true)
  }

  return {
    init: init,
  }
})();

export default Theme;
