import Helper from "../polyfills/helper";
import Api from "../elements/api";
import Notification from "../elements/notification"

const Session = (function(){

  let signInFormElement = null;
  let otpElement = null;
  let loginElement = null;
  let linksElement = null;
  let skipOtpCheck = false;

  function init() {
    signInFormElement = document.getElementById('signinForm');

    if (Helper.isNull(signInFormElement)) { return; }

    otpElement = signInFormElement.querySelector('.otp')
    loginElement = signInFormElement.querySelector('.login')
    linksElement = signInFormElement.querySelector('.login-links')

    handleSignInFormSubmit();
  }


  function handleSignInFormSubmit() {
    signInFormElement.addEventListener('submit', (event) => {
      if (shouldCheckOtpRequired()) {
        checkOtpRequired();

        event.stopPropagation();
        event.preventDefault();
        return false;
      }
    });
  }

  function shouldCheckOtpRequired() {
    if (skipOtpCheck) { return false; }
    return otpElement.classList.contains('is-hidden');
  }

  function checkOtpRequired() {
    const request = new Request(signInFormElement.dataset.otpRequired);

    const data = new URLSearchParams();
    for (const pair of new FormData(signInFormElement)) {
      data.append(pair[0], pair[1]);
    }

    Api.callApi(request, 'POST', data, {"Content-Type": "application/x-www-form-urlencoded"})
      .then((result) => {
        if (result.error) {
          Notification.createToast(result.error)
        } else if (result.otp_required) {
          otpElement.classList.remove('is-hidden')
          loginElement.classList.add('is-hidden')
          if (linksElement) {
            linksElement.classList.add('is-hidden')
          }
        } else {
          signInWithoutOtp();
        }
      })
      .catch(ex => {
        Notification.createToast(ex.message)
      });
  }

  function signInWithoutOtp() {
    skipOtpCheck = true
    signInFormElement.submit();
  }

  return {
    init: init
  }
})();

export default Session;
