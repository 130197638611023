import Cloner from "./clone";
import Api from './api'

const AjaxLink = (function(){

  let ajaxLinks = [];
  let targetElement = null;
  let templateElement = null;

  function init() {
    document.removeEventListener('ajax::refresh', hookUpAjaxLinks)
    document.addEventListener('ajax::refresh', hookUpAjaxLinks)

    window.setTimeout(function(){
      const initialLoadElements = Array.from(document.querySelectorAll('[data-initial-load]'))
      initialLoadElements.forEach(element => {
        showNewContent(element)
      })}, 100);
  }

  function hookUpAjaxLinks() {
    ajaxLinks = Array.from(document.querySelectorAll('[data-async-link]'))
    ajaxLinks.forEach(element => {
      element.removeEventListener('click', handleAjaxLinkClicked)
      element.addEventListener('click', handleAjaxLinkClicked)
    });
  }

  function handleAjaxLinkClicked(event) {
    showNewContent(event.currentTarget)

    event.preventDefault();
    event.stopPropagation();
  }

  function showNewContent(element){
    targetElement = document.querySelector(element.dataset.target);
    templateElement = document.querySelector(element.dataset.template);

    showPlaceHolder();

    const request = new Request(element.getAttribute('src'));
    Api.callApi(request, 'GET' )
      .then((result) => {
        if (targetElement.dataset.replace){
          targetElement.outerHTML = result;
        } else {
          targetElement.innerHTML = result;
        }
        const event = new CustomEvent('ajax::refresh');
        document.dispatchEvent(event);
      }).catch((error) => {
        console.error(error)
    })
  }

  function showPlaceHolder() {
    targetElement.innerHTML = "";
    const clone = Cloner.createElementFromTemplate({}, templateElement);
    targetElement.appendChild(clone);
  }

  return {
    showNewContent: showNewContent,
    init: init
  }
})();

export default AjaxLink
