import Form from './form'
import ResponsiveTable from "./responsive-table";
import RemoteForms from "./remote-form";
import LanguageSwitch from "./language_switch";
import {Slider} from "./slider";
import {Expander} from "./dialog";
import CheckBox from "./checkbox"
import Popup from './popup'
import AjaxLink from "./ajax-link";
import Theme from "../theme";
import FileUpload from "./file-upload";
import Device from "../pwa/device_manager";
import LongRefreshLink from "./long_request";
import Switch from "./switch";
import PushNotificationListener from "./push_notification_listener";
import FileUploadPlaceholder from "./file_upload_placeholder";
import "./circle-progress";
import "./expanding-side-bar";
import Charts from "./chart";
import "./input-toggler"

document.addEventListener("DOMContentLoaded", function() {
  PushNotificationListener.init();
  Form.init();
  FileUploadPlaceholder.init()
  AjaxLink.init();
  Expander.init();
  ResponsiveTable.init();
  RemoteForms.init()
  LanguageSwitch.init();
  FileUpload.init();
  Device.init();
  LongRefreshLink.init();
  Theme.init();
  CheckBox.init();
  Popup.init();
  Switch.init();
  Charts.init();
  FileUploadPlaceholder.init();

  Slider.init((index)=> {
    console.log(index)
    // window.location = index;
  })
});
