import Notification from "./notification";

const PushNotificationListener = (function(){

  function init() {
    document.removeEventListener('push_notification::received', showNotification)
    document.addEventListener('push_notification::received', showNotification)

    document.removeEventListener('push_notification::enabled', enableNotifications)
    document.addEventListener('push_notification::enabled', enableNotifications)

    document.removeEventListener('push_notification::disabled', disableNotifications)
    document.addEventListener('push_notification::disabled', disableNotifications)
  }

  function showNotification(data) {
    Notification.createNotice(data.detail.notification.body)
  }

  function enableNotifications(element) {

  }
  function disableNotifications(element) {

  }
  return {
    init: init
  }
})();

export default PushNotificationListener;
