const ExpandingSidebar = (function(){

  function init(){
    const expandElements = Array.from(document.querySelectorAll('[data-expand-sidebar]'));
    expandElements.forEach(expandElement => {
      expandElement.removeEventListener('click', toggleSidebar)
      expandElement.addEventListener('click', toggleSidebar)
    });
  }

  function toggleSidebar(event){
    const element = event.currentTarget;
    const target = element.dataset.expandSidebar;
    const targetElement = document.querySelector(target);
    if (targetElement.classList.contains('expanded')){
      element.classList.remove('expanded')
      targetElement.classList.remove('expanded')
    } else {
      element.classList.add('expanded')
      targetElement.classList.add('expanded')
    }
  }

  return {
    init: init
  }
})();

document.addEventListener("DOMContentLoaded", function() {
  ExpandingSidebar.init();
});
