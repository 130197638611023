export const Cloner = (function(){

  const createElementFromTemplate = function(data, template) {
    const clone = document.importNode(template.content, true);

    const entries = Object.entries(data);
    entries.forEach(function(entry){
      setProperty(entry[0], entry[1], clone)
    });
    return clone;
  };

  const setProperty = function(property, value, clone, prefix = '') {
    if (value == null) { return;}

    const item = clone.querySelector('[data-property="' + prefix + property + '"]')
    if (item !== undefined && item !== null) {
      if (item.dataset.attribute === 'class') {
        let correctedValue = value.toLowerCase();
        correctedValue = correctedValue.split(' ').join('-');
        item.classList.add(correctedValue);
      }
      else if (item.dataset.attribute == 'id') {
        item.dataset.id = value
      }
      else if (item.nodeName === "IMG") {
        item.setAttribute('src', value)
      } else {
        item.innerText = value;
      }
    }
  };

  return {
    createElementFromTemplate: createElementFromTemplate
  }
})();

export default Cloner;
