import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

const Charts = (function(){

  function init(){
    Array.from(document.querySelectorAll('[data-chart]')).forEach(element => {
      const data = document.querySelector('input[name="' + element.dataset.chart + '"]')

      var myChart = new Chart(element, JSON.parse(data.value));
    })
  }

  return {
    init: init
  }
})();

export default Charts;
