import Rails from '@rails/ujs';
import Helper from "../polyfills/helper";
import Form from "./form";

class RemoteForm {

  constructor(element) {
    this.onAjaxSuccess = this.onAjaxSuccess.bind(this)
    this.onAjaxComplete = this.onAjaxComplete.bind(this)
    this.onSortElementClicked = this.onSortElementClicked.bind(this)
    this.onPagingHandlerClicked = this.onPagingHandlerClicked.bind(this)
    this.submitForm = this.submitForm.bind(this)

    this.element = element;

    if (element.dataset.target) {
      this.targetElement = document.getElementById(element.dataset.target);
    } else {
      this.targetElement = this.element
    }

    this.selected_sort_order = 'desc';
    this.selected_sort_column = null;

    this.initializeSortElements();
    this.initializeAjaxCallback();
  }

  deinit() {
    this.element.removeEventListener('ajax:success', this.onAjaxSuccess);
    this.element.removeEventListener('ajax:complete', this.onAjaxComplete)

    Array.from(this.element.querySelectorAll('.sort-column')).forEach(th => {
      th.removeEventListener('click', this.onSortElementClicked);
    })

    const submitElements = this.element.querySelectorAll('[data-remote-submit]');
    Array.from(submitElements).forEach(submitElement => {
      submitElement.removeEventListener('click', this.submitForm)
    })

    const paginationElement = this.element.querySelector('.pagination');
    if (paginationElement) {
      Array.from(paginationElement.querySelectorAll('a')).forEach(a => {
        a.removeEventListener('click', this.onPagingHandlerClicked);
        a.addEventListener('click', this.onPagingHandlerClicked);
      });
    }

    this.element = null;
    this.targetElement = null;
    this.selected_sort_column = null;
    this.selected_sort_order = null;
  }

  initializeSortElements() {
    const sortColumnElement = this.element.querySelector('#sort_column');
    if (!sortColumnElement) { return; }

    this.selected_sort_column = this.element.querySelector('#sort_column').value;
    this.selected_sort_order = this.element.querySelector('#sort_order').value;

    this.setSortElementHeaders();
    this.attachSortElementHandlers();
    this.attachPagingHandlers();
    this.attachSubmitHandlers();
  }

  // Filtering and sorting
  setSortElementHeaders() {
    Array.from(this.element.querySelectorAll('.sort-column')).forEach(th => {
      if (th.dataset.column === this.selected_sort_column) {
        th.classList.add('sort-active');
        th.classList.add(this.selected_sort_order);
        this.selected_sort_column = th.dataset.column;
      } else {
        th.classList.remove('sort-active')
      }
    });
  }

  attachSortElementHandlers() {
    Array.from(this.element.querySelectorAll('.sort-column')).forEach(th => {
      th.removeEventListener('click', this.onSortElementClicked);
      th.addEventListener('click', this.onSortElementClicked);
    })
  }

  onSortElementClicked(event) {
    const new_sort_column = event.currentTarget.dataset.column;

    if (this.selected_sort_column === new_sort_column ) {
      this.selected_sort_order = this.selected_sort_order === 'desc' ? 'asc' : 'desc'
    } else {
      this.selected_sort_order = 'desc';
    }

    this.selected_sort_column = new_sort_column;

    this.element.querySelector('#sort_column').value = this.selected_sort_column;
    this.element.querySelector('#sort_order').value = this.selected_sort_order;
    Rails.fire(this.element, "submit");
  }

  attachPagingHandlers() {
    const paginationElement = this.element.querySelector('.pagination');
    if (!paginationElement) { return; }

    Array.from(paginationElement.querySelectorAll('a')).forEach(a => {
      a.removeEventListener('click', this.onPagingHandlerClicked);
      a.addEventListener('click', this.onPagingHandlerClicked);
    });
  }

  onPagingHandlerClicked(event){
    event.stopPropagation();
    event.preventDefault();
    const page = Helper.getParameterByName('page', event.currentTarget.href);
    this.element.querySelector('#page').value = page;

    const company = Helper.getParameterByName('company', event.currentTarget.href);
    if (company && this.element.querySelector('#company')) {
      this.element.querySelector('#company').value = company;
    }

    Rails.fire(this.element, "submit");
    return false;
  }

  attachSubmitHandlers() {
    const submitElements = this.element.querySelectorAll('[data-remote-submit]');
    if (!submitElements) { return; }

    Array.from(submitElements).forEach(submitElement => {
      submitElement.removeEventListener('click', this.submitForm)
      submitElement.addEventListener('click', this.submitForm);
    })
  }

  submitForm(event) {
    Rails.fire(event.currentTarget.form, "submit");
  }

  // Callbacks
  initializeAjaxCallback(){
    this.element.removeEventListener('ajax:success', this.onAjaxSuccess);
    this.element.addEventListener('ajax:success', this.onAjaxSuccess);

    this.element.removeEventListener('ajax:complete', this.onAjaxComplete)
    this.element.addEventListener('ajax:complete', this.onAjaxComplete)

    this.element.removeEventListener('ajax:beforeSend', this.updateCurrentLocationWithGetUrl)
    this.element.addEventListener('ajax:beforeSend', this.updateCurrentLocationWithGetUrl)
  }

  onAjaxSuccess(event){
    if (event.detail[2].status === 200) {
      const xhr = event.detail[2];
      const stringData = xhr.response;
      if (stringData.includes("Turbolinks")) { return; }

      if (!event.detail[2].getResponseHeader('Content-Type').includes('application/json')) {
        // Html content
        Form.clearErrors(this.element);
        this.handleResponseHtml(event.detail[2].responseText)
        this.fireAjaxRefreshEvent()
        return;
      }

      const data = event.detail[0]
      if (!Helper.isEmptyObject(data.errors)) {
        Form.handleErrors(this.element, data.errors)
      } else {
        Form.clearErrors(this.element);
      }

      if (data.html && data.html.length > 1) {
        this.handleResponseHtml(data.html);
      }

      // TODO form zelf verversen? delen wel / delen niet?

      this.fireAjaxRefreshEvent()
    }
  }

  onAjaxComplete(event){
    const responseCode = event.detail[0].status
    const responseText = event.detail[0].responseText;

    if (responseCode == 400 && responseText) {
      const responseData = JSON.parse(responseText)

      const event = new CustomEvent('ajax::bad_request', { detail: {errors: responseData.errors} });
      document.dispatchEvent(event);
      Form.handleErrors(this.element, responseData.errors)
      this.fireAjaxRefreshEvent()
    }
    else if (responseCode === 302 || responseCode === 301) {
      const redirect = JSON.parse(event.detail[0].response).redirect;
      window.setTimeout(() => {
        if (window.location.href.replace('#', '') == window.location.origin + redirect) {
          location.reload();
        } else {
          window.location.href = window.location.origin + redirect;
        }
        // TODO moet dit? this.fireAjaxRefreshEvent()
      }, 100);
    }
  }

  updateCurrentLocationWithGetUrl(xhr) {
    window.history.replaceState(null, document.title, xhr.detail[1].url.replace(/[^?=&]+=(&|$)/g,"").replace(/&$/,""))
  }
  handleResponseHtml(responseHtml){
    this.targetElement.innerHTML = responseHtml;
  }

  fireAjaxRefreshEvent() {
    // window.setTimeout(function() {
      const event = new CustomEvent('ajax::refresh', {detail: {}});
      document.dispatchEvent(event);
    // }, 1);
  }
}

const RemoteForms = (function(){

  let forms = [];

  function init() {
    document.removeEventListener('ajax::refresh', init)
    document.addEventListener('ajax::refresh', init)

    forms.forEach(form => {
      form.deinit();
    })

    forms = [];

    const asyncContainers = document.querySelectorAll('[data-remote]');
    if (asyncContainers == null || asyncContainers.length === 0) { return; }

    Array.from(asyncContainers).forEach((asyncContainer) => {
      forms.push(new RemoteForm(asyncContainer));
    })
  }

  return {
    init: init
  }
})();

export default RemoteForms;
