const CheckBox = (function(){

  let checkBoxElements = [];

  function init() {
    checkBoxElements = document.querySelectorAll('[data-check-box]')
    Array.from(checkBoxElements).forEach(element => {
      element.removeEventListener('change', onChanged)
      element.addEventListener('change', onChanged)
      handleCheckbox(element)
    })
  }

  function onChanged(event) {
    handleCheckbox(event.currentTarget)
  }

  function handleCheckbox(element, forceInactive = false) {
    const checkBox = element.querySelector('input[type="radio"], input[type="checkbox"]');

    if (checkBox.checked) {
      element.classList.add('check-box-active')
    } else {
      element.classList.remove('check-box-active')
    }

    if (checkBox.type == 'radio' && !forceInactive) {
      checkBoxElements.forEach(other_element => {
        if (other_element != element) {
          handleCheckbox(other_element, true)
        }
      })
    }
  }

  return {
    init: init
  }
})();

export default CheckBox;
