import Storage from '../polyfills/storage'

const Slider = (function(){
  let sliderContainer;
  let sliderElements;
  let callbackRef;
  let arrowRight;

  function init(callback) {
    sliderContainer = document.querySelector('[data-slider]');
    if (!sliderContainer) { return;}

    callbackRef = callback

    showHelp()

    sliderElements = Array.from(sliderContainer.querySelectorAll('[data-slider-element]'))
    sliderElements.forEach((sliderElement) =>{
      sliderElement.addEventListener("click", (event) =>{
        hideHelp();
        handleSliderElementClick(sliderElement);
      });

      // handleSliderElementClick(sliderElement);
    });
  }

  function handleSliderElementClick(sliderElement) {
    const t = getContainerHalfPoint()
    const i = getElementHalfPoint(sliderElement)
    const left = i - t;

    const selectedClass = sliderElement.dataset.clickClass;

    setScrollPosition(left);
    unHighlightSliderElements(selectedClass);
    highLightSelectedSliderElement(sliderElement, selectedClass);
  }

  function getContainerHalfPoint() {
    const n = sliderContainer.offsetWidth
    const t = sliderContainer.clientLeft;
    return t + n / 2;
  }

  function getElementHalfPoint(element) {
    const t = _getOffset(element);
    const i = element.offsetWidth;
    return t.left + i / 2
  }

  function _getOffset(n){
    for (var t = 0, i = 0; n && !isNaN(n.offsetLeft) && !isNaN(n.offsetTop); )
      t += n.offsetLeft - n.scrollLeft,
        i += n.offsetTop - n.scrollTop,
        n = n.offsetParent;
    return {
      top: i,
      left: t
    }
  }

  function setScrollPosition(left){
    sliderContainer.scroll != undefined ? sliderContainer.scroll({
      top: 0,
      left: left
    }) : sliderContainer.scrollLeft = left;
  }

  function unHighlightSliderElements(selectedClass) {
    if (!sliderElements) { return; }

    for (var n = 0; n < sliderElements.length; n++)
      sliderElements[n].classList.remove(selectedClass)
  }

  function highLightSelectedSliderElement(element, selectedClass){
    if (selectedClass) {
      element.classList.add(selectedClass)
    }

    const event = new CustomEvent('slider::selected', {detail: {id: element.dataset.id}});
    document.dispatchEvent(event);

    if (callbackRef != undefined) {
      callbackRef(element.dataset.id)
    }
  }

  function showHelp() {
    if (Storage.getItem('shownSliderHelp')) { return; }

    const arrowRight = sliderContainer.querySelector('.arrow-icon-right');
    if (arrowRight) {
      arrowRight.classList.add('arrow-right--active')
    }

    const arrowLeft = sliderContainer.querySelector('.arrow-icon-left');
    if (arrowLeft) {
      arrowLeft.classList.add('arrow-left--active')
    }
  }

  function hideHelp() {
    const arrowRight = sliderContainer.querySelector('.arrow-icon-right');
    if (arrowRight) {
      arrowRight.classList.remove('arrow-right--active')
    }

    const arrowLeft = sliderContainer.querySelector('.arrow-icon-left');
    if (arrowLeft) {
      arrowLeft.classList.remove('arrow-left--active')
    }

    Storage.setItem('shownSliderHelp', '1')
  }

  return {
    init: init,
    handleSliderElementClick: handleSliderElementClick
  }
})();

export {Slider}
