import mapModule from './map-module'

export const MapHelper =(function () {
  let search = {};
  let map = {};

  function initMap(){
    const latitudeElement = document.getElementById('latitude')
    const longitudeElement = document.getElementById('longitude')

    if (document.getElementById('map') === null) { return; }
    map = mapModule.init('/maps/token', '/locations', 'map', annotationCallback, latitudeElement.value, longitudeElement.value);
  }

  function initSearch(){
    const searchInputSubmit = document.getElementById('maps_search_submit');
    const searchInput = document.getElementById('maps_search');

    if (searchInput === undefined || searchInput == null) { return; }

    search = mapModule.initSearch();

    searchInputSubmit.addEventListener('click', (event) => {
      const searchedValue = searchInput.value;
      if (searchedValue.length < 3) {
        const longitudeElement = document.getElementById('longitude')
        if (longitudeElement && longitudeElement.dataset.submitForm){
          longitudeElement.form.submit()
        }
        return;
      }
      searchValue(searchedValue);
    });
  }

  function init(){
    initMap()
    initSearch()
  }

  function searchValue(searchedValue) {
    if (searchedValue.length === 0) {
      return;
    }

    mapModule.searchForLocations(search, searchedValue, function (result) {
      const latitudeElement = document.getElementById('latitude')
      const longitudeElement = document.getElementById('longitude')

      if (result.success) {
        latitudeElement.value = result.closestLocation.latitude
        longitudeElement.value = result.closestLocation.longitude

        if (longitudeElement.dataset.submitForm){
          longitudeElement.form.submit()
        }
        // document.getElementById('map_marker').classList.add('map-marker--active');
      } else {
        if (longitudeElement.dataset.submitForm){
          longitudeElement.form.submit()
        }
        // document.getElementById('map_marker').classList.remove('map-marker--active');
      }
    });
  }

  function annotationCallback(annotation) {
    const div = document.createElement("div");
    div.className = "location__annotation";
    return div;
  }

  return {
    init: init
  }
})();
