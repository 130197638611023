//%div{data:{popup: 'true', 'active-class': 'is-active', 'passive-class': 'is-passive'}}

import Helper from "../polyfills/helper";

const Popup = (function(){

  'use strict'

  function init() {
    document.removeEventListener('ajax::refresh', init)
    document.addEventListener('ajax::refresh', init)

    let popups = document.querySelectorAll('[data-popup]');
    let lazyLoaders = document.querySelectorAll('[data-src]');
    if (!('IntersectionObserver' in window) ||
      !('IntersectionObserverEntry' in window) ||
      !('intersectionRatio' in window.IntersectionObserverEntry.prototype)) {
      handleNoIntersectObserver(popups)
      handleNoIntersectObserverLazyLoaders(lazyLoaders)
      return;
    }

    handleInit(popups)
    observeEntries(popups)
    observeLazyLoaders(lazyLoaders)
  }

  function handleNoIntersectObserver(entries) {
    for (let i = 0; i < entries.length; i++) {
      handleElementInViewPort(entries[i], true)
    }
  }

  function handleNoIntersectObserverLazyLoaders(entries) {
    window.setTimeout(()=> {
      handleNoIntersectObserver(entries)
    }, 200);
  }

  function handleInit(entries) {
    for (let i = 0; i < entries.length; i++) {
      if (!isElementInViewport(entries[i])) {
        // allow for optional 'negative' class
        handleElementInViewPort(entries[i], false)
      }
    }
  }

  function observeEntries(entries) {
    const io = new IntersectionObserver(function (entries) {
      for (let i = 0; i < entries.length; i++) {
        if (entries[i].isIntersecting) {
          handleElementInViewPort(entries[i].target, true);
        }
      }
    });

    for (let i = 0; i < entries.length; i++) {
      io.observe(entries[i]);
    }
  }

  function observeLazyLoaders(entries){
    window.setTimeout(() => {
      observeEntries(entries)
    }, 200);
  }

  function isElementInViewport (el) {
    const rect = el.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
  }

  function handleElementInViewPort(el, inViewPort){
    const element = el;

    if (!inViewPort) {
      if (element.dataset.passiveClass) {
        element.classList.add(el.dataset.passiveClass)
      }
    } else {
      if (element.dataset.src && Helper.isEmpty(element.src)) {
        element.src = element.dataset.src
      }
      if (element.dataset.activeClass) {
        element.classList.add(element.dataset.activeClass);
      }
    }
  }

  return {
    init: init
  }
})();

export default Popup
